/* App.css */
body, html {
  margin: 0;
  padding: 0;
  font-family: 'Arial', sans-serif;
  background-color: #61dafb;
}

.App {
  text-align: center;
}
.hero img {
  max-width:500px;
}
.fbhero {
  color: white;
  font-weight: bold;
  width: 70%;
  margin-left: auto;  /* Added to center the box */
  margin-right: auto; /* Added to center the box */
  margin-bottom: 70px;
  padding: 10px;
}

.moonrise {
  width:100%;
  align-content: center;
  justify-content: center;
  margin-bottom:40px;
}
nav {
  color: #ffffff;
  padding: 1rem;
  display: flex;
  align-items: center;
}
nav a {
  color:black;
}
nav h1 {
  text-align: left;
  font-size: 3em;
  color: #2b2d30;
}
nav img {
  padding-right:20px;
}
nav h1 {
  text-align: left;
  font-size: 2em;
  color: white;
}
.hero {
  background-color: #61dafb; /* Soft blue background for hero section */
}

.services {
  padding: 50px 0px;
  text-align: center;
  background-color: white;
  color: #000;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0,0,0,0.1);
  width: 80%;
  max-width: 1200px;
  margin: auto;
}
.services h2 {
  color: #2b2d30;
}

footer {
  background-color: #61dafb; /* Dark blue background for footer */
  color: #2b2d30;
  padding: 20px;
}
/* Add to App.css */

.privacy-policy {
  padding: 50px 20px;
  background-color: #61dafb;
  text-align: left;
  font-size: 0.5em;
}

.privacy-policy h2, .terms-of-use h2 {
  color: #2b2d30; /* Or the primary color of your theme */
}
.terms-of-use {
  padding: 0px 20px;
  background-color: #61dafb;
  text-align: left;
  font-size: 0.5em;
}
/* Add to App.css */
.pressicon {
  margin-top:50px;
  padding-right: 10px;
}
.subscribe-btn {
  background-color: #f7d347; /* YELLOW background */
  border: none;
  color: black;
  padding: 15px 32px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 16px;
  font-weight: bold;
  margin: 50px 2px 60px 0px;
  cursor: pointer;
  border-radius: 8px;
  transition: background-color 0.3s ease;
  width: 300px;
}

button.signin {
  background-color: blue; /* Green background */
  border: none;
  color: white;
  padding: 15px 32px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 16px;
  margin: 4px 2px;
  cursor: pointer;
  border-radius: 8px;
  transition: background-color 0.3s ease;
  font-weight: bold;
}
.presskit a {
  font-size: 20px;
  height: 30px;
}
.presskit {
  display: flex;
padding-left: 15px;
  padding-top:0px;
  font-size: 20px;
}

.responsive {
  width: 100%; /* Make the image width 100% of its container */
  max-width: 800px; /* Maximum width of the image */
  height: auto; /* Adjust height automatically */
}
button.signin:hover {
  background-color: #61dafb; /* Darker shade of green on hover */
}
.subscribe-btn:hover {
  opacity:95%; /* Darker shade of green on hover */

}
.video iframe {
  width: 100%;
  max-width: 560px; /* Maximum width of the video */
  height: auto; /* Adjust height automatically */
  aspect-ratio: 16 / 9; /* Maintain aspect ratio of 16:9 */
  margin-top: 60px;
}

/* Responsive design for smaller screens */
@media (max-width: 768px) {

  .hero img, .services img {
    margin-top:20px;
    width: 90%;
    max-width: 450px;
    display: inline-flex;
  }
.navbar {
  font-size: 12px;
  height: 150px;
  margin-bottom:0px;
  margin-top: 0px;

 }
  .footer img {
    width: 350px;
  }

  .responsive {
    width: 30%; /* Make the image width 100% of its container */
    max-width: 800px; /* Maximum width of the image */
    height: auto; /* Adjust height automatically */
  }

}
/* Responsive design for smaller screens */
@media (max-width: 768px) {
  .footer {
    text-align: center;
    padding: 20px 10px; /* Adjust padding for mobile */
  }

  .footer img {
    max-width: 100%; /* Ensures images are responsive and do not overflow */
    height: auto; /* Maintain aspect ratio */
  }

  /* Additional responsive adjustments */
  .privacy-policy, .terms-of-use {
    padding: 20px 10px; /* Adjust padding for mobile */
    font-size: 0.5em; /* Increase font size for readability on mobile */
  }

  nav h1, .services h2, .privacy-policy h2, .terms-of-use h2 {
    font-size: 1.5em; /* Increase font size for headings on mobile */
  }

  .services, .hero img, .services img {
    width: 100%; /* Adjust width for smaller screens */
  }
}
