.filter-container {
    display: flex;
    justify-content: center;
    margin-bottom: 20px;
}

.filter-container input[type="text"] {
    padding: 10px;
    font-size: 16px;
    border: 1px solid #ced4da;
    border-radius: 4px;
    min-width:200px;
    max-width: 300px; /* Adjust as needed */
    transition: border-color 0.3s ease-in-out;
}

.filter-container input[type="text"]:focus {
    border-color: #007bff; /* Highlight input border when focused */
    outline: none;
}

.items-per-page-selector {
    font-size: .5em;
    float:left;
    width: 200px;
}
.filter-container {
}
.error {
    color: #dc3545;
    text-align: center;
}

.files {
    display:flex;
    flex-wrap: wrap;
    gap: 16px;
    justify-content: center;
    padding: 16px;
}
.pdficon img {
    width: 10px;
}
.file-item {
    border: 1px solid #ccc;
    padding: 8px;
    border-radius: 8px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    background-color: #ced4da;
    font-size: 12px;
    max-width:200px;
    margin: 20px;
    float: left;
}
h2 {
    color:white;
}
.file-item img {
    max-width: 100%;
    height: auto;
    display: block;
    margin-bottom: 8px;
}
.file-search {
    max-width:400px;
}
.responsive img {
   display: none;
}
.pressicon {
    width: 200px;
}

@media (max-width: 768px) {
    .file-item {
        width: calc(50% - 8px); /* Adjust file items for smaller screens */
    }
    .file-search {
        width:50px;
    }
    .pagination-controls {
        width:500px;
    }

}

@media (max-width: 400px) {
    .file-item {
        width: 100%; /* Full width for file items on very small screens */
    }
    .filter-container {
        max-width:400px;
    }

    /* Adjust the footer and any other elements for smaller screens */
    .footer img.responsive {
        max-width: 80%; /* Adjust the width of footer images on very small screens */
        height: auto;
    }
    .files img {
       width:400px;
    }

}

/* Ensure images are responsive */
img.responsive {
    max-width: 100%; /* Limit image width to the width of its container */
    height: auto; /* Maintain the aspect ratio */
}

/* Pagination Controls */
.pagination-controls {
    display: flex;
    margin-top: 20px;
    max-width:100%;
    height:50px;
}


.pagination-controls button {
    padding: 5px 10px;
    margin: 0 5px;
    background-color: #007bff;
    color: white;
    border: none;
    border-radius: 4px;
    width: 90px;
    cursor: pointer;

}

.pagination-controls button:hover {
    background-color: #0056b3;
}

.pagination-controls button:disabled {
    background-color: #ccc;
    cursor: not-allowed;
}
/* Adjust the tenant input container for smaller screens */
.tenant-input-container {
    color:black;
    font-size: 16px;
    display: block;
    max-width: 500px;
    margin: 30px auto;
    padding: 20px;
    background-color: #f8f9fa;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    box-sizing: border-box;
}

/* Responsive adjustments for the filter container and pagination controls */
.filter-container {
    margin-top: 30px;
    padding: 20px;
    background: #61dafb;
    border-radius: 5px;
    color: black;
}
